const $ = require('jquery');
// const footer = require('./../../footer');
if (!window.$) {
    window.$ = $;
}
// tab切换时，更新底部面板位置
function updateTabContentPosition (index) {
    const leftPer = index === 0 ? '10%' : index === 1 ? '-15%' : '-50%';
    $('#tabContent').attr('style', `transform:translate(${leftPer})`);
}
// 更新tab、内容的样式
let tabIndex = 0;
function updateTabAndContentClass (index) {
    // if (index === tabIndex) {
    //     return;
    // }
    // 移除上一个index active
    $('#tabs .item').eq(tabIndex).children().toggleClass('active');
    // 激活当前
    $('#tabs .item').eq(index).children().toggleClass('active');

    tabIndex = index;
}

// 渲染头
function renderNav () {
    const menuList = juheMenus.site_header
    console.log(menuList)
    //获取模版里面的内容
    let source = $("#header-template").html();
    //模版渲染
    let template = Handlebars.compile(source);
    let html = template(menuList);
    //模版装载到dom节点上
    $(".header-menu").html(html);
}



$(document).ready(function () {
    updateTabAndContentClass(0);
    updateTabContentPosition(0);
    $('#tabs li').on('click', function (e) {
        const index = $('#tabs li').index(this);
        console.log('index', index)
        updateTabAndContentClass(index);

    });

    // 渲染头部导航
    renderNav()

});
// 处理注册、返回官网链接
$('.register-btn').on('click', function () {
    window.location.href = 'https://www.juhe.cn/register' + window.location.search;
});
$('.juhe-link').on('click', function () {
    window.location.href = 'https://www.juhe.cn/' + window.location.search;
});

// 点击跳转去登录
$('.cardBox_btn').on('click', function () {
    window.open('https://apimaster-demo.juhe.cn/')
})
$('.btbannerright').on('click', function () {
    window.open('https://apimaster-demo.juhe.cn/')
})
$('.left_nav_join').on('click', function () {
    window.open('https://apimaster-demo.juhe.cn/#/user/login')
})